<template>
  <div class="page not-found">
    <BreadcrumbsComponent title="Страница не найдена" />
    <div class="page__inner">
      <SectionComponent>
        <div class="not-found__inner">
          <div class="not-found__text">
            <IconComponent class="not-found__text-icon" name="not-found" />
            <span class="not-found__text-title">
              Такой страницы пока не существует но мы подумаем как её использовать, а пока можете...
            </span>
          </div>
          <div class="not-found__content">
            <SetsListComponent :sets="sets" position="sides" />
          </div>
          <div class="not-found__btn">
            <router-link :to="{ name: 'home' }" class="btn btn--lg btn--main-outline">
              <span>Вернуться на главную</span>
            </router-link>
          </div>
        </div>
      </SectionComponent>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import IconComponent from "components/IconComponent.vue";
import SetsListComponent from "components/SetsListComponent.vue";
import NOT_FOUND_PAGE from "@/graphql/pages/NotFoundPage.graphql";

export default {
  components: {
    SetsListComponent,
    IconComponent,
    SectionComponent,
    BreadcrumbsComponent,
  },
  async asyncData({ res, apollo, store }) {
    await apollo.defaultClient
      .query({
        query: NOT_FOUND_PAGE,
      })
      .then(({ data }) => {
        store.state.page_404.sets = data.sets_paginate?.data || [];
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
      })
      .catch(() => {});
    if (res) {
      res.status(404);
    }
  },
  data() {
    return {
      staticSet: {
        title: "Каталог всех наборов",
        more: true,
      },
    };
  },
  computed: {
    sets() {
      let blocks = JSON.parse(JSON.stringify(this.$store.state.page_404.sets)) || [];
      blocks.push(this.staticSet);
      return blocks;
    },
  },
  metaInfo: {
    title: "Станица не найдена",
  },
};
</script>

<style lang="stylus">
.not-found {
  &__inner {
    display flex
    flex-direction column
    align-items center
    padding-top 60px
    gap 50px
    +below(600px) {
      padding-top 0
    }
    +below(480px) {
      gap: 30px
    }
  }

  &__content {
    align-self stretch
  }

  &__text {
    max-width 673px
    display flex
    flex-direction column
    align-items center
    text-align center
    gap 30px
  }

  &__text-icon {
    width 100px
    height 100px
  }

  &__text-title {
    font-size 1.125em
    line-height 28px
    color var(--dark-2)
  }
}
</style>
